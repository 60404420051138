<template>
  <div class="box">
    <div class="filter">
      <div class="total" v-if="!mobileWidth">
        <div class="tip">
          Total Content
        </div>
        <div class="num">
          {{ total }}
        </div>
      </div>
      <div class="search-box" :style="{ margin: mobileWidth ? '0' : '0 20px' }">
        <div class="input-box">
          <input
            v-on:keyup.enter="handleSearch"
            v-model="filter.search"
            type="text"
            placeholder="Search for a marketing material..."
          />
        </div>
        <div class="btn" @click="handleSearch">Search</div>
      </div>

      <div class="item">
        <div class="tip"></div>
        <router-link to="/marketing/create">
          <Button type="primary">Upload Content</Button>
        </router-link>
      </div>
    </div>

    <div class="cards">
      <Spin v-if="loading" fix></Spin>

      <div class="empty" v-if="list.length === 0">
        No Data
      </div>

      <div v-else>
        <Row type="flex" justify="start" :gutter="16">
          <i-col
            :key="item.id"
            v-for="item in list"
            :style="
              screenWidth
                ? 'width:24%'
                : mobileWidth
                ? 'width:50%'
                : 'width:33.33%'
            "
          >
            >
            <router-link :to="`/marketing/${item.id}/detail`" target="_blank">
              <div class="item">
                <div class="imgWrap">
                  <div class="img-box">
                    <img alt="" class="avatar" :src="item.cover.url" />
                  </div>
                  <div class="user-avatar">
                    <img alt="" class="avatar" :src="item.company.avatar.url" />
                  </div>
                </div>

                <!-- <router-link
                :to="
                  `/search?q=${encodeURIComponent(
                    item.marketing_name
                  )}&type=product&marketing_name=${encodeURIComponent(
                    item.marketing_name
                  )}`
                "
              > -->
                <div class="user">
                  <div class="user-name">
                    {{ item.title || "--" }}
                  </div>
                </div>
                <!-- </router-link> -->

                <div class="name">
                  <span>{{ item.description }}</span>
                </div>

                <div class="download">
                  <Button size="small" @click.prevent="edit(item)">Edit</Button>
                  <Divider type="vertical" />
                  <Button
                    size="small"
                    @click.prevent="removeMarketing(item)"
                    type="error"
                    >Delete</Button
                  >
                </div>
              </div>
            </router-link>
          </i-col>
        </Row>

        <div class="page" v-if="mobileWidth">
          <Page :current="page" :page-size="limit" @on-change="pageChange" />
        </div>
        <div class="page" v-else>
          <Page
            transfer
            :current="page"
            :page-size="limit"
            :total="total"
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryMarketingList, _removeMarketing } = api;

export default {
  name: "marketing",

  created() {
    this.queryMarketingList();
  },

  data() {
    return {
      screenWidth: window.innerWidth > 1200,
      mobileWidth: window.innerWidth < 800,
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      }
    };
  },

  methods: {
    edit(item) {
      this.$router.push(`/marketing/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.queryMarketingList();
    },

    queryMarketingList() {
      this.loading = true;

      _queryMarketingList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryMarketingList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMarketingList();
    },

    removeMarketing(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeMarketing({
            id: item.id
          })
            .then(() => {
              this.$Message.success("success");
              this.queryMarketingList(); // refresh
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.$Modal.remove();
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .filter {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    .total {
      color: #333;
      .tip {
      }
      .num {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .search-box {
      // flex: 1;
      flex: none;
      height: 40px;
      display: flex;
      margin: 0 20px;
      .input-box {
        position: relative;
        flex: 1;
        height: 100%;
        background: #fff;
        line-height: initial;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          background: transparent;
          border: none;
          color: #444;
          caret-color: #888;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
        }
      }
      .btn {
        cursor: pointer;
        background: #fff;
        color: @primary-color;
        border: 1px solid @primary-color;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
      }
    }
  }
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .item {
      margin-bottom: 20px;
      border: 1px solid #ccc;
      background: #fff;
      .imgWrap {
        position: relative;
        display: block;
      }
      .img-box {
        width: 100%;
        height: 0px;
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        .avatar {
          display: block;
          width: 100%;
        }
      }

      .name {
        padding: 10px;
        height: 120px;
        border-bottom: 1px solid #ccc;
        color: #666;
        overflow: hidden;
      }
      .download {
        padding: 10px;
      }
      .user-avatar {
        position: absolute;
        bottom: -13px;
        left: 10px;
        z-index: 2;
        width: 50px;
        height: 50px;
        background: #eee;
        border: 1px solid #f2f2f2;
        border-radius: 50%;
        margin-right: 10px;
        overflow: hidden;
        .avatar {
          display: block;
          width: 100%;
        }
      }
      .user {
        padding: 12px 10px 0;
        margin-top: 5px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .user-name {
          font-size: 15px;
          color: #000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tags {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .tag {
          color: #fff;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 4px;
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
  }
  .page {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
